import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"cookies"} />
		<Helmet>
			<title>
				Profilozó Személyiségteszt | Sziklay Bence
			</title>
			<meta name={"description"} content={"Értsd meg önmagad egy Enneagram alapú személyiségteszttel! Lépj az önfelfedezés útjára most!"} />
			<meta property={"og:title"} content={"Profilozó Személyiségteszt | Sziklay Bence"} />
			<meta property={"og:description"} content={"Értsd meg önmagad egy Enneagram alapú személyiségteszttel! Lépj az önfelfedezés útjára most!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/creativeOG.webp?v=2024-08-06T08:29:54.434Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
		</Helmet>
		<Components.NavBar />
		<Section>
			<Box min-width="100px" min-height="100px">
				<Text margin="0px 0px 0px 0px" font="--headline1">
					Sütik
				</Text>
				<Components.Cookies margin="30px 0px 0px 0px" />
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link1" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b3caaa009abe001f026b4e"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});